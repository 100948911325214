// Monster Images
import curiosityMonster1Img from "../assets/images/monsters/curiosity-monster-1.png";
import curiosityMonster2Img from "../assets/images/monsters/curiosity-monster-2.png";
import curiosityMonster3Img from "../assets/images/monsters/curiosity-monster-3.png";
import curiosityMonster4Img from "../assets/images/monsters/curiosity-monster-4.png";
import curiosityMonster5Img from "../assets/images/monsters/curiosity-monster-5.png";
import curiosityMonster6Img from "../assets/images/monsters/curiosity-monster-6.png";
import curiosityMonster7Img from "../assets/images/monsters/curiosity-monster-7.png";
import curiosityMonster8Img from "../assets/images/monsters/curiosity-monster-8.png";
import curiosityMonsterPainterImg from "../assets/images/monsters/painter.png";

import fridaImg from "../assets/images/monsters/frida-kahlo.png";
import gustavImg from "../assets/images/monsters/gustav-klimt.png";
import leonardoDaVinciImg from "../assets/images/monsters/leonardo-da-vinci.png";
import michelangeloImg from "../assets/images/monsters/michelangelo.png";
import hieronymusBoschImg from "../assets/images/monsters/hieronymus-bosch.png";
import peterPaulRubensImg from "../assets/images/monsters/peter-paul-rubens.png";
import rembrandtVanRijnImg from "../assets/images/monsters/rembrandt-van-rijn.png";
import johannesVermeerImg from "../assets/images/monsters/johannes-vermeer.png";
import caravaggioImg from "../assets/images/monsters/caravaggio.png";
import franciscoGoyaImg from "../assets/images/monsters/francisco-goya.png";
import katsushikaHokusaiImg from "../assets/images/monsters/katsushika-hokusai.png";
import utagawaHiroshigeImg from "../assets/images/monsters/utagawa-hiroshige.png";
import claudeMonetImg from "../assets/images/monsters/claude-monet.png";
import paulCezanneImg from "../assets/images/monsters/paul-cezanne.png";
import edvardMunchImg from "../assets/images/monsters/edvard-munch.png";
import vincentImg from "../assets/images/monsters/vincent-van-gogh.png";

import pabloPicassoImg from "../assets/images/monsters/pablo-picasso.png";
import wassilyKandinskyImg from "../assets/images/monsters/wasily-kandinsky.png";
import banksyImg from "../assets/images/monsters/banksy.png";
import egonSchieleImg from "../assets/images/monsters/egon-schiele.png";
import andyWarholImg from "../assets/images/monsters/andy-warhol.png";
import salvadorDaliImg from "../assets/images/monsters/salvador-dali.png";
import tamaraDeLempickaImg from "../assets/images/monsters/tamara-de-lempica.png";
import yayoiKusamaImg from "../assets/images/monsters/yayoi-kusama.png";

import scientistImg from "../assets/images/monsters/scientist.png";
import composerImg from "../assets/images/monsters/composer.png";


export const MONSTERS = {
    curiosityMonster1: curiosityMonster1Img,
    curiosityMonster2: curiosityMonster2Img,
    curiosityMonster3: curiosityMonster3Img,
    curiosityMonster4: curiosityMonster4Img,
    curiosityMonster5: curiosityMonster5Img,
    curiosityMonster6: curiosityMonster6Img,
    curiosityMonster7: curiosityMonster7Img,
    curiosityMonster8: curiosityMonster8Img,

    art: curiosityMonsterPainterImg,

    fridaKahlo: fridaImg,
    pabloPicasso: pabloPicassoImg,
    gustavKlimt: gustavImg,
    vincentVanGogh: vincentImg,
    caravaggio: caravaggioImg,
    hieronymusBosch: hieronymusBoschImg,
    wassilyKandinsky: wassilyKandinskyImg,
    katsushikaHokusai: katsushikaHokusaiImg,
    utagawaHiroshige: utagawaHiroshigeImg,
    johannesVermeer: johannesVermeerImg,
    banksy: banksyImg,
    rembrandtVanRijn: rembrandtVanRijnImg,
    egonSchiele: egonSchieleImg,
    leonardoDaVinci: leonardoDaVinciImg,
    michelangelo: michelangeloImg,
    paulCezanne: paulCezanneImg,
    peterPaulRubens: peterPaulRubensImg,
    claudeMonet: claudeMonetImg,
    andyWarhol: andyWarholImg,
    franciscoGoya: franciscoGoyaImg,
    edvardMunch: edvardMunchImg,
    salvadorDali: salvadorDaliImg,
    tamaraDeLempicka: tamaraDeLempickaImg,
    yayoiKusama: yayoiKusamaImg,

    science: scientistImg,
    carlLinnaeus: scientistImg,
    galileoGalilei: scientistImg,


    composers: composerImg,

    serbs: curiosityMonster3Img,
    americans: curiosityMonster1Img,
    germans: curiosityMonster5Img,
    italians: curiosityMonster2Img,
    french: curiosityMonster6Img,
}
